<template>
  <v-container fluid>
    <router-view />
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
